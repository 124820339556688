<template>
  <div class="result-wrapper">
    <div class="shadow-wrapper">
      <div class="img-wrapper" v-html="img"/>
      <h1 class="title">{{ title }}</h1>
      <p class="description" v-html="description"></p>
    </div>
    <router-link :to="nextPath" class="btn">{{ btnText }}</router-link>
  </div>
</template>

<script>
export default {
  name: "ResultContent",
  props: {
    title: {
      required: true,
      type: String
    },
    description: {
      required: false,
      type: String
    },
    img: {
      required: true,
      type: String
    },
    nextPath: {
      required: true,
      type: String
    },
    btnText: {
      required: true,
      type: String
    },
    titleAbsolute: {
      required: false,
      type: Boolean
    }
  }
}
</script>
