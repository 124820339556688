<template>
  <div class="container negative-wrapper">
    <Header class="pt-sm-2 pb-sm-1 pt-md-3 pb-md-2 pt-lg-4 pb-lg-2" :static-text="$t('questions.mainTitle_2')"/>
    <PageLayout class="mt-0 px-0">
      <ProgressLine class="w-100" total-step="7" :progress="7"/>
      <ResultContent title="You’re good to go!"
                     next-path="/"
                     :title-absolute="true"
                     btn-text="Done"
                     :img="svg"
                     description="Based on your responses, you are <br> cleared to go to work."/>
    </PageLayout>
  </div>
</template>

<script>
import metaTag from "../helper/meta-tag";
import ResultContent from "../components/ResultContent";
import ProgressLine from "../components/ProgressLine";
import Header from "../components/Header";
import AnalyticsService from "../services/analytics-service";

let el = null;
export default {
  name: "NegativeRes",
  components: {
    ResultContent,
    Header,
    ProgressLine
  },
  data() {
    return {
      svg: "<svg xmlns=\"http://www.w3.org/2000/svg\" width=\"272\" height=\"272\" viewBox=\"0 0 272 272\">\n" +
          "    <g fill=\"none\" fill-rule=\"evenodd\">\n" +
          "        <g>\n" +
          "            <g>\n" +
          "                <g transform=\"translate(-827 -332) translate(827 332) translate(10.81 10.81)\">\n" +
          "                    <circle cx=\"125\" cy=\"125\" r=\"125\" stroke=\"#34BE95\" stroke-width=\"20.27\" opacity=\".15\"/>\n" +
          "                    <path fill=\"#34BE95\" fill-rule=\"nonzero\" d=\"M159.92 97.054l-48.99 50.528-19.505-20.114c-1.438-1.482-3.774-1.482-5.212 0-1.437 1.482-1.437 3.892 0 5.374l22.114 22.805c.715.737 1.659 1.11 2.602 1.11.944 0 1.887-.373 2.61-1.11l51.6-53.211c1.437-1.483 1.437-3.892 0-5.375-1.438-1.482-3.775-1.49-5.22-.007z\" opacity=\".8\"/>\n" +
          "                </g>\n" +
          "            </g>\n" +
          "        </g>\n" +
          "    </g>\n" +
          "</svg>\n"
    }
  },
  created() {
    el = document.getElementById('app');
    el.classList.add('negative-bg');
    if (window.innerWidth < 767) this.svg = '<svg xmlns="http://www.w3.org/2000/svg" width="201" height="201" viewBox="0 0 201 201">\n' +
        '    <g fill="none" fill-rule="evenodd">\n' +
        '        <g>\n' +
        '            <g>\n' +
        '                <g transform="translate(-87 -259) translate(87 259) translate(8 8)">\n' +
        '                    <circle cx="92.5" cy="92.5" r="92.5" stroke="#34BE95" stroke-width="15" opacity=".15"/>\n' +
        '                    <path fill="#34BE95" fill-rule="nonzero" d="M118.34 71.82l-36.252 37.39-14.434-14.884c-1.063-1.097-2.793-1.097-3.856 0-1.064 1.097-1.064 2.88 0 3.977l16.364 16.876c.53.545 1.227.821 1.926.821.698 0 1.396-.276 1.93-.821l38.184-39.377c1.064-1.096 1.064-2.88 0-3.977-1.063-1.096-2.793-1.102-3.862-.005z" opacity=".8"/>\n' +
        '                </g>\n' +
        '            </g>\n' +
        '        </g>\n' +
        '    </g>\n' +
        '</svg>\n'
    if (!sessionStorage.getItem('unlock-page')) {
      this.$router.push('/');
    }
    else {
      AnalyticsService.logCheckInProcessCompletedEvent("negative");
    }
  },
  mounted() {
    metaTag('#9dd8c7');
  },
  destroyed() {
    metaTag('#d8e9ea');
    el.classList.remove('negative-bg');
  }
}
</script>
